import React from "react";
import reactStringReplace from "react-string-replace";
interface Props {
  text: string;
  textHighlight: string;
}

const SubTitleView: React.FC<Props> = (props) => {
  const { text, textHighlight } = props;

  return (
    <div className="Text-style-subtitle Text-shadow Text-tilt mt-6 ">
      {textHighlight.length > 0
        ? reactStringReplace(text, textHighlight, (match, index) => (
            <span key={index} className="Text-yellow">
              {match}
            </span>
          ))
        : text}
    </div>
  );
};

export default SubTitleView;
