import { TemplateDescriptor } from "lib/data";
import { create } from "zustand";
import produce from "immer";

interface AppState {
  pages: TemplateDescriptor[];
  currentPage: TemplateDescriptor | undefined;
  currentIndex: number;
  changePage: () => void;
  uniqueIndex: number;
  setPages: (pages: TemplateDescriptor[] | undefined) => void;
  cachedMedia: { [key: string]: string };
  cachedMediaFetching: { [key: string]: boolean };
  getMediaFromCache: (url: string) => Promise<string | undefined>;
  playMuted: boolean;
  setPlayMuted: (muted: boolean) => void;
}

const useAppStore = create<AppState>((set, get) => ({
  pages: [],
  currentPage: undefined,
  currentIndex: -1,
  uniqueIndex: 0,
  cachedMedia: {},
  playMuted: false,
  setPlayMuted: (muted: boolean) =>
    set(
      produce((state: AppState) => {
        state.playMuted = muted;
      })
    ),
  cachedMediaFetching: {},
  getMediaFromCache: async (remoteURL) => {
    let blobURL = get().cachedMedia[remoteURL];
    if (!blobURL) {
      setTimeout(async () => {
        console.debug(`Media is not in cache ${remoteURL}`);
        try {
          if (get().cachedMediaFetching[remoteURL]) {
            console.debug(`Already fetching media ${remoteURL}`);
            return;
          }
          console.debug(`Start fetching media: ${remoteURL}`);
          set(
            produce((state: AppState) => {
              state.cachedMediaFetching[remoteURL] = true;
            })
          );
          let response = await fetch(remoteURL);
          let blob = await response.blob();
          let url = URL.createObjectURL(blob);
          set(
            produce((state: AppState) => {
              state.cachedMedia[remoteURL] = url;
              state.cachedMediaFetching[remoteURL] = false;
            })
          );
        } catch (error) {
          console.error(`Error fetching media to cache ${error}`);
          set(
            produce((state: AppState) => {
              state.cachedMediaFetching[remoteURL] = false;
            })
          );
        }
      }, 100);
    }

    return blobURL;
  },
  changePage: () =>
    set(
      produce((state: AppState) => {
        state.uniqueIndex += 1;
        if (!state.pages || state.pages.length === 0) {
          state.currentIndex = -1;
          state.currentPage = undefined;
        } else {
          let index = state.currentIndex;
          if (state.currentIndex === state.pages.length - 1) {
            index = 0;
          } else {
            index += 1;
          }
          state.currentIndex = index;
          state.currentPage = state.pages[index];
        }
      })
    ),
  setPages: (pages: TemplateDescriptor[] | undefined) =>
    set(
      produce((state: AppState) => {
        if (pages) {
          state.pages = pages;
          // D1: pages is empty
          if (!pages || pages.length === 0) {
            state.currentIndex = -1;
            state.currentPage = undefined;
            return;
          }
          // D2: pages has fewer items than old pages
          if (state.currentIndex >= pages.length) {
            state.currentIndex = 0;
            state.currentPage = pages[0];
            return;
          }

          if (state.currentIndex === -1) {
            state.currentIndex = 0;
          }

          state.currentPage = pages[state.currentIndex];
        }
      })
    ),
}));

export default useAppStore;
