import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import OddView from "../components/OddView";
import BackgroundView from "../components/BackgroundView";
import TeamView from "../components/TeamView";
import TimeView from "../components/TimeView";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

const Template2: React.FC<Props> = (props) => {
  const { template } = props;
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);

  return (
    <BackgroundView
      background={template.background}
      title={template.betModel.title}
      subTitle={template.betModel.subTitle}
      titleHighlight={template.betModel.titleHighlight}
      subTitleHighlight={template.betModel.subTitleHighlight}
    >
      <div className="relative flex flex-col justify-center text-center w-[1920px] mt-16 tall:mt-64">
        <div className="inline-flex justify-center items-center">
          <div className="inline-flex w-[450px]">
            <TeamView team={template.betModel.teams!![0]} />
          </div>
          <div className="inline-flex w-[450px] items-center justify-center">
            <TimeView
              time={template.betModel.time!!}
              singleLine={false}
              simple={false}
            />
          </div>
          <div className="inline-flex w-[450px]">
            <TeamView team={template.betModel.teams!![1]} />
          </div>
        </div>
        <div className="inline-flex justify-center mt-8 tall:mt-32">
          {template.betModel.odds!!.map((element, index) => {
            return (
              <div key={index} className="inline-block w-[450px]">
                <OddView key={index} odd={element} className="Odd-medium" />
              </div>
            );
          })}
        </div>
      </div>
    </BackgroundView>
  );
};

export default Template2;
