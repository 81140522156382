import React from "react";
import { TimeModel } from "../lib/data";
interface Props {
  time: TimeModel;
}

const NewTimeView: React.FC<Props> = (props) => {
  const { time } = props;

  return (
    <div className="inline-flex items-center justify-center text-center flex-col">
      {time.day !== undefined && (
        <div className="text-white inline-block Time-text Time-text-very-small Text-shadow">
          {time.day}
        </div>
      )}
      <div className="relative inline-block  Time-text Text-white Time-text-very-small Text-shadow">
        {time.hour}
      </div>
    </div>
  );
};

export default NewTimeView;
