import React from "react";
import { BackgroundModel } from "../lib/data";
import MainTitleView from "./MainTitleView";
import SubTitleView from "./SubTitleView";
interface Props {
  background: BackgroundModel;
  title?: string; // TOP 10 CELE MAI PARIATE EVENIMENTE
  titleHighlight?: string; // TOP 10
  subTitle?: string; // STARTING SOON
  subTitleHighlight?: string; // STARTING
  children?: React.ReactNode;
  altLogo?: boolean;
  titleSmaller?: boolean;
  extraTopSpace?: boolean;
}

const NewBackgroundView: React.FC<Props> = (props) => {
  const {
    background,
    title,

    titleHighlight,
    subTitle,
    subTitleHighlight,
    children,
    altLogo,
    titleSmaller,
    extraTopSpace,
  } = props;

  return (
    <div className="fixed w-full h-full text-white Fade-in">
      <img
        className="absolute left-0 top-0 w-full h-full object-cover"
        src={background.backgroundImageURL}
        alt="background"
      />

      <div className="absolute left-0 top-0 w-full h-full Background-overlay"></div>
      <div className="absolute left-[40%] bottom-0 Triangle-bottom animate-fade-in-up"></div>

      {altLogo !== true && (
        <background.logo className="absolute w-[120px] h-[120px] ml-[94px] animate-fade-in-down-logo" />
      )}
      {altLogo === true && (
        <background.logo className="absolute w-[78px] h-[78px] bottom-[18px] right-[44px] animate-fade-in-right-logo delay-500" />
      )}

      <div
        className={`relative block ${
          altLogo === true ? "mt-[8px]" : "mt-[92px]"
        } w-[1400px] animate-fade-in-left`}
      >
        <div
          className={`inline-block ml-[78px] ${
            extraTopSpace === true ? "mt-24" : "mt-6"
          } `}
        >
          {title !== undefined && (
            <MainTitleView
              text={title!!}
              smaller={titleSmaller}
              textHighlight={titleHighlight === undefined ? "" : titleHighlight}
            />
          )}
          {subTitle !== undefined && (
            <SubTitleView
              text={subTitle!!}
              textHighlight={
                subTitleHighlight === undefined ? "" : subTitleHighlight
              }
            />
          )}
        </div>
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default NewBackgroundView;
