import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import OddView from "../components/OddView";
import BackgroundView from "../components/BackgroundView";
import TeamView from "../components/TeamView";
import TimeView from "../components/TimeView";
import MatchView from "../components/MatchView";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

const Template14: React.FC<Props> = (props) => {
  const { template } = props;
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);
  return (
    <BackgroundView
      background={template.background}
      title={template.betModel.title}
      subTitle={template.betModel.subTitle}
      titleHighlight={template.betModel.titleHighlight}
      subTitleHighlight={template.betModel.subTitleHighlight}
    >
      <div className="relative flex flex-col justify-center w-[1920px] mt-16">
        <div className="inline-flex justify-center items-center tall:mt-16">
          <div className="inline-flex w-[450px]">
            <TeamView team={template.betModel.teams!![0]} />
          </div>
          <div className="inline-flex w-[450px] items-center justify-center">
            <TimeView
              time={template.betModel.time!!}
              singleLine={false}
              simple={false}
            />
          </div>
          <div className="inline-flex w-[450px]">
            <TeamView team={template.betModel.teams!![1]} />
          </div>
        </div>
        <div className="inline-flex justify-center mt-8 mb-16 tall:mb-24 tall:mt-24">
          {template.betModel.odds!!.map((element, index) => {
            return (
              <div key={index} className="block text-center w-[450px]">
                <OddView key={index} odd={element} className="Odd-normal" />
              </div>
            );
          })}
        </div>

        {template.betModel.matches!!.map((element, index) => {
          return (
            <div
              key={index}
              className="block mt-2 ml-[260px] h-[78px] animate-fade-in-left-match tall:mb-8"
              style={{ animationDuration: `${1800 + 100 * index}ms` }}
            >
              <MatchView
                key={index}
                match={element}
                timeBeforeOdds={true}
                className={"Match-small"}
              />
            </div>
          );
        })}
      </div>
    </BackgroundView>
  );
};

export default Template14;
