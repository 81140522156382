import { Props } from "lib/data";
import { useEffect, useRef, useState } from "react";
import useAppStore from "store/appstore";

const TemplateHTMLPage: React.FC<Props> = ({ mediaURL, duration = 15 }) => {
  const changePage = useAppStore((state) => state.changePage);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const timeout = useRef<any | null>(null);

  const onError = () => {
    console.log(`FAILED TO LOAD URL ${mediaURL}`);
    //   clearInterval(timeout.current);
    //   changePage();
  };

  useEffect(() => {
    timeout.current = setTimeout(changePage, duration * 1000);
    // getMediaFromCache(url!).then((url) => {
    //   if (!url) {
    //     console.log("missing cachedURL");
    //     clearInterval(timeout.current);
    //     changePage();
    //   } else {
    //     console.log(`set cached url ${url}`);
    //     setCachedURL(url!);
    //   }
    // });
  }, [changePage, duration]);
  return (
    <div className="w-full h-full flex fixed top-0 left-0">
      <iframe
        className="inline-block flex-1"
        src={mediaURL}
        scrolling="no"
        title="HTML PAGE"
        onLoad={() => {
          console.log(`URL ${mediaURL} LOADED`);
          setIframeLoaded(true);
        }}
        onError={onError}
        style={{
          border: "none",
          visibility: `${iframeLoaded ? "visible" : "hidden"}`,
        }}
      />
    </div>
  );
};
export default TemplateHTMLPage;
