import React from "react";
import { LockIcon } from "../icons/icons";
import { OddModel } from "../lib/data";
interface Props {
	odd: OddModel;
	className: string;
}

const OddView: React.FC<Props> = (props) => {
	const { odd, className } = props;

	return (
		<div className={`inline-block ${className} ${odd.backgroundColor} animate-fade-in`}>
			<div className="block relative overflow-visible">
				{odd.prefix !== undefined && <div className="float-left Prefix">{odd.prefix}</div>}
				{!odd.locked && <div className={`float-left Name ${odd.textColor}`}>{odd.name}</div>}

				{odd.suffixIcon !== undefined && (
					<div className="float-left Suffix mr-[24px] mt-[17px]">
						<odd.suffixIcon fill="#ffdb01" />
					</div>
				)}

				{odd.title !== undefined && <div className="absolute Title Text-shadow Text-tilt">{odd.title}</div>}
				{odd.exponent !== undefined && <div className="absolute Exponent">{odd.exponent}</div>}

				{odd.locked && (
					<div className="float-left Lock mx-[56px] mt-[17px]">
						<LockIcon fill="#ffdb01" />
					</div>
				)}
			</div>
		</div>
	);
};

export default OddView;
