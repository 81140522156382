import React from "react";
import { MatchModel } from "../lib/data";
import OddView from "./OddView";
interface Props {
	match: MatchModel;
	className: string;
}

const MatchViewPlus: React.FC<Props> = (props) => {
	const { match, className } = props;

	return (
		<div className={`inline-block w-[100%] ${className}`}>
			<div className="float-left w-[40%] Title Text-shadow">
				<span className={match.team1TextColor}>{match.team1}</span>{" "}
				<span className={`ml-12 ${match.team2TextColor}`}>{match.team2}</span>
			</div>

			<div className="float-left">
				{match.odds!!.map((element, index) => {
					return (
						<div
							key={index}
							className={`inline-block text-center ${
								className === "Match-small" ? "w-[130px] mr-20" : "w-[160px] mr-20"
							}`}
						>
							<OddView
								key={index}
								odd={element}
								className={className === "Match-normal" ? "Odd-normal" : "Odd-small"}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MatchViewPlus;
