import React from "react";
import { LockIcon } from "../icons/icons";
import { OddModel } from "../lib/data";
interface Props {
  odd: OddModel;
  className: string;
}

const NewOddView: React.FC<Props> = (props) => {
  const { odd, className } = props;

  return (
    <div
      className={`inline-block ${className} ${
        odd.special === true ? "bg-[#11AD2C]" : "Background-black"
      } animate-fade-in w-full`}
    >
      <div
        className={`inline-flex relative px-2 items-center ${
          odd.prefix !== undefined ? "justify-between" : "justify-center"
        } w-full`}
      >
        {odd.title !== undefined && (
          <div className="inline-block Title Text-shadow Text-tilt">
            {odd.title}
          </div>
        )}
        {odd.prefix !== undefined && (
          <div
            className={`inline-block text-center Prefix italic ${
              odd.special === true ? "text-[#5DD472]" : "Text-white"
            }`}
          >
            {odd.prefix}
          </div>
        )}
        {!odd.locked && (
          <div
            className={`inline-block text-center items-center justify-center Name ${
              odd.special === true
                ? "Text-white"
                : odd.textColor ?? "Text-yellow"
            }`}
          >
            {odd.name}
          </div>
        )}

        {odd.locked && (
          <div className="inline-flex flex-1 items-center justify-center Lock">
            <LockIcon fill="#ffdb01" />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewOddView;
