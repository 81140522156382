import React from "react";
import { TimeModel } from "../lib/data";
interface Props {
	time: TimeModel;
	singleLine: boolean;
	simple: boolean;
	small?: boolean | false;
}

const TimeView: React.FC<Props> = (props) => {
	const { time, singleLine, simple, small } = props;

	return (
		<div className={`inline-flex items-center justify-center text-center ${singleLine ? "" : "flex-wrap"}`}>
			{time.day !== undefined && (
				<div
					className={`Text-white ${singleLine ? "relative inline-block" : "relative block w-[100%]"} ${
						simple ? "Time-text-simple" : "Time-text"
					}${small === true ? " Time-text-small" : ""} Text-shadow`}
				>
					{time.day}
				</div>
			)}
			<div
				className={`${singleLine ? "relative inline-block ml-6" : "relative block w-[100%]"} ${
					simple ? "Time-text-simple" : "Time-text Text-white"
				}${small === true ? " Time-text-small" : ""} Text-shadow`}
			>
				{time.hour}
			</div>
		</div>
	);
};

export default TimeView;
