import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import BackgroundView from "../components/BackgroundView";
import MatchView from "../components/MatchView";
import SubTitleView from "../components/SubTitleView";
import reactStringReplace from "react-string-replace";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

const Template15: React.FC<Props> = (props) => {
  const { template } = props;

  const subTitle = template.betModel.subTitle;
  const subTitleHighlight = template.betModel.subTitleHighlight || "";
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);
  return (
    <BackgroundView
      background={template.background}
      title={template.betModel.title}
      titleHighlight={template.betModel.titleHighlight}
    >
      <div className="relative w-[1920px] text-center Text-style-subtitle Text-shadow Text-tilt tall:mt-8">
        {subTitleHighlight.length > 0
          ? reactStringReplace(subTitle, subTitleHighlight, (match, index) => (
              <span key={index} className="Text-yellow">
                {match}
              </span>
            ))
          : subTitle}
      </div>

      <div className="relative flex flex-col justify-center w-[1920px] mt-16">
        {template.betModel.matches!!.map((element, index) => {
          return (
            <div
              key={index}
              className="block ml-[124px] h-[65px] animate-fade-in-left-match tall:mt-8"
              style={{ animationDuration: `${1800 + 100 * index}ms` }}
            >
              <MatchView
                key={index}
                match={element}
                timeBeforeOdds={true}
                className={"Match-small"}
                suffix={"Last bets!!!"}
              />
            </div>
          );
        })}
      </div>
    </BackgroundView>
  );
};

export default Template15;
