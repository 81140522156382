import React from "react";
import reactStringReplace from "react-string-replace";
interface Props {
  text: string;
  textHighlight: string;
  smaller?: boolean;
}

const MainTitleView: React.FC<Props> = (props) => {
  const { text, textHighlight, smaller } = props;

  return (
    <div
      className={`Text-style-title ${
        smaller === true ? "smaller" : ""
      } Text-shadow Text-tilt `}
    >
      {textHighlight.length > 0
        ? reactStringReplace(text, textHighlight, (match, index) => (
            <span key={index} className="Text-yellow">
              {match}
            </span>
          ))
        : text}
    </div>
  );
};

export default MainTitleView;
