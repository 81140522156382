import React, { useEffect } from "react";
import { TemplateModel } from "../lib/data";
import OddView from "../components/OddView";
import BackgroundView from "../components/BackgroundView";
import NewBackgroundView from "components/NewBackgroundView";
import NewOddView from "components/NewOddView";
import useAppStore from "store/appstore";
interface Props {
  template: TemplateModel;
}

const Template13: React.FC<Props> = (props) => {
  const { template } = props;
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    let timeout = setTimeout(changePage, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [changePage]);
  return (
    <NewBackgroundView
      background={template.background}
      title={template.betModel.title}
      subTitle={template.betModel.subTitle}
      titleHighlight={template.betModel.titleHighlight}
      subTitleHighlight={template.betModel.subTitleHighlight}
      altLogo={true}
      titleSmaller={true}
      extraTopSpace={true}
    >
      <div className="relative flex flex-col justify-center text-center w-[1920px] mt-52">
        <div className="inline-flex justify-center items-center tall:mt-64">
          {(template.betModel.matches ?? [])[0].odds!!.map((element, index) => {
            return (
              <div
                key={index}
                className={`block text-center ${index > 0 && "ml-[300px]"}`}
              >
                <NewOddView
                  key={index}
                  odd={element}
                  className={`${
                    index === 0 ? "Odd-extralarge" : "Odd-large mt-10"
                  }`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </NewBackgroundView>
  );
};

export default Template13;
